import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import '../css/layout.css'
//data = {"site":{"siteMetadata":{"title":"BAULEN - Software Engineering Company with Great Design based in Las Palmas de Gran Canaria."}}}
const lang = 'es'
const facebookChat = `
<!-- Load Facebook SDK for JavaScript -->
<div id="fb-root"></div>
<script>(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/es_ES/sdk/xfbml.customerchat.js#xfbml=1&version=v2.12&autoLogAppEvents=1';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));</script>

<!-- Your customer chat code -->
<div class="fb-customerchat"
  attribution=setup_tool
  page_id="1584237571796918"
  theme_color="#EC1639"
  logged_in_greeting="¡Hola! Soy el chatbot de baulen.      ¿En qué te puedo ayudar?"
  logged_out_greeting="¡Hola! Soy el chatbot de baulen.      ¿En qué te puedo ayudar?">
</div>
`
const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            {
              name: 'keywords',
              content:
                'Las Palmas de Gran Canaria, Las Palmas, Sejas, Baulen, Startup, App, desarrollo, chatbots, diseño, software, React, React Native',
            },
          ]}
        >
          <html className="" lang={lang} />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title[lang]} />
        <div className="fb" dangerouslySetInnerHTML={{ __html: facebookChat}}></div>
        <div className="content">{children}</div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

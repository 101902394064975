import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const HeaderWrapper = styled.header`
  height: 0;
`
const LogoWrapper = styled.div`
  top: 48px;
  left: 48px;
  z-index: 100;
  position: absolute;
  width: 120px;
`
const LogoSpan = styled.span``

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <LogoWrapper>
      <Link to="/">
        <LogoSpan>
          <img
            src={require('../images/baulen-software-company-canarias.svg')}
            alt="baulen"
          />
          <span className="hide">baulen</span>
        </LogoSpan>
      </Link>
    </LogoWrapper>
  </HeaderWrapper>
)

export default Header
